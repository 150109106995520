exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-games-100-astroflap-js": () => import("./../../../src/pages/games/100-astroflap.js" /* webpackChunkName: "component---src-pages-games-100-astroflap-js" */),
  "component---src-pages-games-astek-astroflap-js": () => import("./../../../src/pages/games/astek-astroflap.js" /* webpackChunkName: "component---src-pages-games-astek-astroflap-js" */),
  "component---src-pages-games-astroflap-js": () => import("./../../../src/pages/games/astroflap.js" /* webpackChunkName: "component---src-pages-games-astroflap-js" */),
  "component---src-pages-games-atos-astroflap-js": () => import("./../../../src/pages/games/atos-astroflap.js" /* webpackChunkName: "component---src-pages-games-atos-astroflap-js" */),
  "component---src-pages-games-avenga-astroflap-js": () => import("./../../../src/pages/games/avenga-astroflap.js" /* webpackChunkName: "component---src-pages-games-avenga-astroflap-js" */),
  "component---src-pages-games-brains-and-beards-astroflap-js": () => import("./../../../src/pages/games/brains-and-beards-astroflap.js" /* webpackChunkName: "component---src-pages-games-brains-and-beards-astroflap-js" */),
  "component---src-pages-games-devsdata-astroflap-js": () => import("./../../../src/pages/games/devsdata-astroflap.js" /* webpackChunkName: "component---src-pages-games-devsdata-astroflap-js" */),
  "component---src-pages-games-foxrunner-js": () => import("./../../../src/pages/games/foxrunner.js" /* webpackChunkName: "component---src-pages-games-foxrunner-js" */),
  "component---src-pages-games-gokidz-astroflap-js": () => import("./../../../src/pages/games/gokidz-astroflap.js" /* webpackChunkName: "component---src-pages-games-gokidz-astroflap-js" */),
  "component---src-pages-games-hand-2-band-astroflap-js": () => import("./../../../src/pages/games/hand2band-astroflap.js" /* webpackChunkName: "component---src-pages-games-hand-2-band-astroflap-js" */),
  "component---src-pages-games-idego-astroflap-js": () => import("./../../../src/pages/games/idego-astroflap.js" /* webpackChunkName: "component---src-pages-games-idego-astroflap-js" */),
  "component---src-pages-games-masterborn-astroflap-js": () => import("./../../../src/pages/games/masterborn-astroflap.js" /* webpackChunkName: "component---src-pages-games-masterborn-astroflap-js" */),
  "component---src-pages-games-mobica-astroflap-js": () => import("./../../../src/pages/games/mobica-astroflap.js" /* webpackChunkName: "component---src-pages-games-mobica-astroflap-js" */),
  "component---src-pages-games-roboklocki-astroflap-js": () => import("./../../../src/pages/games/roboklocki-astroflap.js" /* webpackChunkName: "component---src-pages-games-roboklocki-astroflap-js" */),
  "component---src-pages-games-siili-astroflap-js": () => import("./../../../src/pages/games/siili-astroflap.js" /* webpackChunkName: "component---src-pages-games-siili-astroflap-js" */),
  "component---src-pages-games-smokefusion-js": () => import("./../../../src/pages/games/smokefusion.js" /* webpackChunkName: "component---src-pages-games-smokefusion-js" */),
  "component---src-pages-games-tutlo-astroflap-js": () => import("./../../../src/pages/games/tutlo-astroflap.js" /* webpackChunkName: "component---src-pages-games-tutlo-astroflap-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

